<template>
  <pcg-box>
    <div class="pcg-box-inner-header d-flex justify-content-between">
      <span><i class="pcg-icon-envelope-alt"></i>{{ $t('components.abk_messages_box.title') }}</span>
      <router-link v-if="messages && totalMessages > messages.length"
                   class="pcg-link pcg-small"
                   :to="{ name: 'user_messages_index_path' }">
        {{ $t('general.show_more') }}
      </router-link>
    </div>
    <ul v-if="messages && messages.length" class="p-0 m-0">
      <router-link v-for="message in messages"
                   :key="message.id"
                   :to="{ name: linkPath, params: { id: message.id } }"
                   tag="li"
                   class="message d-flex">
        <pcg-avatar size="extra-small" class="mr-3" :img="message.sender.avatar"/>
        <div class="message-container">
          <div class="pcg-dark-gray-color">
            <span class="pcg-main-color pcg-font-weight-medium pcg-link sender-name-link">
              {{ message.sender.name }}
            </span>
            <span v-if="message.sender.role">{{ message.sender.role }}</span>
            <span class="pcg-font-weight-medium pcg-gray-color">{{ formatDate(message.date) }}</span>
          </div>
          <div class="message-text" v-html="message.text"></div>
        </div>
      </router-link>
    </ul>
    <span v-else class="pl-4 pcg-no-records">{{ $t('components.abk_messages_box.no_records') }}</span>
    <div v-if="showFooter" class="footer text-center d-flex flex-wrap justify-content-between">
      <pcg-btn size="small">{{ $t('components.abk_messages_box.write_message') }}</pcg-btn>
      <pcg-btn size="small" variant="additional">{{ $t('components.abk_messages_box.create_newsletter') }}</pcg-btn>
    </div>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkMessagesBox',
  props: {
    messages: Array,
    totalMessages: Number,
    showFooter: Boolean,
    linkPath: String
  },
  methods: {
    formatDate (date) {
      const newDate = moment(date)
      return newDate.isValid() ? newDate.format('DD.MM.YYYY') : null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  /deep/ .box {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .pcg-box-inner-header {
    padding-left: 25px;
    padding-right: 25px;
  }
  .message {
    padding: 10px 25px;
    font-size: $font-size-s;
    &:not(:last-child) {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &:hover {
      background-color: $pcg-the-lightest-gray;
      cursor: pointer;
      .message-text {
        &:after {
          background: linear-gradient(to right, rgba(0, 0, 0, 0), $pcg-the-lightest-gray 80%);
        }
      }
    }
  }
  .message-text {
    font-weight: 500;
    color: $pcg-dark-gray;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 40px;
      top: 0;
      right: 0;
      background: linear-gradient(to right, rgba(0, 0, 0, 0), $pcg-white 80%);
    }
  }
  .message-container {
    max-width: 100%;
    overflow: hidden;
    *:not(:last-child) {
      margin-right: .3em;
    }
  }
  .footer {
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 10px;
    border-top: 1px solid $pcg-lightest-gray;
    button {
      margin: 20px auto 0;
    }
  }
  .sender-name-link {
    &:hover {
      text-decoration: underline;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .message {
      &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
      &:hover {
        background-color: $pcg-the-lightest-gray;
        .message-text {
          &:after {
            background: linear-gradient(to right, rgba(0, 0, 0, 0), $pcg-the-lightest-gray 80%);
          }
        }
      }
    }
    .message-text {
      color: $pcg-dark-gray;
      &:after {
        background: linear-gradient(to right, rgba(0, 0, 0, 0), $pcg-white 80%);
      }
    }
  }
</style>
