import { render, staticRenderFns } from "./CareerOfficeDashboard.vue?vue&type=template&id=1dc7878b&scoped=true&"
import script from "./CareerOfficeDashboard.vue?vue&type=script&lang=js&"
export * from "./CareerOfficeDashboard.vue?vue&type=script&lang=js&"
import style0 from "./CareerOfficeDashboard.vue?vue&type=style&index=0&id=1dc7878b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dc7878b",
  null
  
)

export default component.exports